<template>
  <div>
    <div v-if="rolechats && rolechats.length">
      <ion-card v-for="room of rolechats" :key="room.id" class="mb-2 rc-card" :class="{'rc-card-read': room.is_read === true}">
        <router-link :to="{ name: 'rolechat-room', params: { id: room.id } }" class="text-black clickable-item-hov">
          <span :class="{ 'unread-chat': room.is_read === false }" class="rc-title">{{ room.title }}</span>
          <div :class="{ 'unread-chat': room.is_read === false }">
            <span v-if="room.latest_msg">{{ truncateText(room.latest_msg, 200) }}</span
            ><i v-else>No messages yet</i>
          </div>
        </router-link>
      </ion-card>

      <p v-if="nextPageExists" class="clickable-item-hov" @click="requestLoadMore">Load More</p>
    </div>
    <div v-else class="my-4">
      <div class="mb-2">No active roleplay chats.</div>
      <div>
        <router-link :to="{ name: 'rolechats', query: { tab: 'discover' } }" @click.prevent="goToDiscover"
          >CLICK HERE</router-link
        >
        to start one!
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Action, Getter } from 's-vuex-class';
import namespace from '@/shared/store/namespace';
import { truncateText } from '@/shared/utils/string';
import { mainStore } from '@/shared/pinia-store/main';

@Options({
  name: 'ActiveRolechats',
  components: {},
})
export default class ActiveRolechats extends Vue {
  public isLoading = false;

  get = get;
  truncateText = truncateText;

  @Getter('rolechats', { namespace: namespace.RolechatModule })
  public rolechats!: any[];

  @Getter('rolechatsPaging', { namespace: namespace.RolechatModule })
  public rolechatsPaging!: any;

  @Action('getRolechats', { namespace: namespace.RolechatModule })
  public getRolechats!: any;

  @Action('rolechatsNextPage', { namespace: namespace.RolechatModule })
  public rolechatsNextPage!: any;

  public get nextPageExists() {
    return !!this.rolechatsPaging.next;
  }

  public get dark() {
    const { dark } = mainStore();
    return dark.value;
  }
  
  public async fetchRolechats() {
    this.isLoading = true;
    await this.getRolechats({ from: 'activechats' });
    this.isLoading = false;
  }

  public async requestLoadMore(ev: CustomEvent) {
    if (!this.rolechatsPaging.next) {
      (ev?.target as any).complete();
    } else {
      await this.rolechatsNextPage();
    }
  }

  public goToDiscover() {
    this.$emit('changeTab', { value: 'discover' });
  }

  mounted() {
    this.fetchRolechats();
  }
}
</script>

<style scoped lang="sass">
.unread-chat
  font-weight: bold
.rc-card
  padding: 0.5rem
  padding-left: 1rem
  border: 1px solid var(--ion-color-primary)
.rc-card-read
  background-color: #FFF !important
  border: none
.dark 
  border: none
.dark .rc-card-read
  background-color: #17074C66 !important
.rc-title
  font-size: 1.2rem
</style>
