<template>
  <ion-segment
    scrollable
    mode="md"
    color="primary"
    class="tabs compact no-shadow bg-transparent"
    :value="activeIndex"
    @ion-change="segmentChanged($event)"
  >
    <ion-segment-button v-for="(tab, index) of tabs" :key="index" class="shrink bg-transparent" :value="index">
      <ion-label class="d-flex"
        >{{ tab.name }}
        <ion-badge
          v-if="tab.name === 'Requests' && get(tabCounts, tab.value)"
          color="danger"
          class="text-white ml-1 d-flex align-items-center"
        >
          {{ tabCounts[tab.value] > 0 ? tabCounts[tab.value] : null }}
        </ion-badge>

        <span v-else-if="get(tabCounts, tab.value)" class="ml-1">({{ tabCounts[tab.value] }})</span>
      </ion-label>
    </ion-segment-button>
  </ion-segment>
</template>


<script lang="ts" setup>
import { Tab } from '@/shared/types/static-types';

const props = defineProps({
  tabs: {
    type: Array as PropType<Tab[]>,
    required: true,
  },
  activeIndex: {
    type: Number,
    default: 0,
  },
  tabCounts: {
    type: Object,
    default: {},
  },
});

const emits = defineEmits(['update:activeIndex', 'tab-changed']);
const tabs = toRef(props, 'tabs');
const segmentChanged = ($event: CustomEvent) => {
  const tabIndex = $event.detail.value as number;
  const tab = tabs.value[tabIndex];
  emits('update:activeIndex', tabIndex);
  emits('tab-changed', tab);
};
</script>

<style scoped lang="sass">
.shrink
  flex: none !important
</style>
